.logo {
    height: 4rem;
}

.portal-wrapper {
    max-width: 50rem;
    margin: 0 auto;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.portal-card {
    cursor: pointer;
    animation: appear 0.4s ease-in-out;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
